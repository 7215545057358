import styled from 'styled-components'
import {
  Text,
  Flex,
  Heading,
  IconButton,
  // ArrowBackIcon,
  NotificationDot,
  ChevronLeftIcon,
  Button,
  // Box,
} from 'packages/uikit'
import { useExpertModeManager } from 'state/user/hooks'
import GlobalSettings from 'components/Menu/GlobalSettings'
// import Link from 'next/link'
// import { isMobile } from 'react-device-detect'
import { useTranslation } from 'contexts/Localization'
// import Transactions from './Transactions'
import { useRouter } from 'next/router'
import { NextLinkFromReactRouter } from 'components/NextLink'
import QuestionHelper from '../QuestionHelper'

interface Props {
  title: string
  subtitle: string
  helper?: string | any
  backTo?: string
  noConfig?: boolean
}

const AppHeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  width: 100%;
  margin-bottom: 12px;

  ${({ theme }) => theme.mediaQueries.xs} {
    flex-direction: unset;
    padding: 16px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 24px;
  }
`

const StyledHeading = styled(Heading)`
  font-size: 28px;
`

const StyledFlex = styled(Flex)`
  gap: 5px;
`

const StyledButton = styled(Button)`
  text-align: center;
  font-weight: bold;

  ${({ theme }) => theme.mediaQueries.xxs} {
    min-width: 85px;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 4px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    min-width: 174px;
    padding: 8px;
    border-radius: 8px;
  }
`

const AppHeader: React.FC<Props> = ({ title, subtitle, helper, backTo, noConfig = false }) => {
  const [expertMode] = useExpertModeManager()

  const router = useRouter()
  const { t } = useTranslation()

  return (
    <AppHeaderContainer>
      <Flex width="100%" alignItems="center" mr={noConfig ? 0 : '16px'}>
        {backTo && (
          <Flex
            as={NextLinkFromReactRouter}
            to={backTo}
            background="var(--primary)"
            borderRadius="50%"
            border="1px solid var(--white-50)"
            minWidth="34px"
            height="34px"
            flex="0"
            justifyContent="center"
            alignItems="center"
            mr="8px"
          >
            {/* <Link passHref href={backTo}> */}
            {/* <IconButton as="a"> */}
            <ChevronLeftIcon color="var(--white)" width="26px" />
            {/* </IconButton> */}
            {/* </Link> */}
          </Flex>
        )}
        <Flex width="100%" flexDirection="column">
          <StyledHeading as="h2">{title}</StyledHeading>
          {/* {title !== 'Your Liquidity' && <StyledHeading as="h2">{title}</StyledHeading>}
          {title === 'Your Liquidity' && (
            <Flex width="100%" justifyContent="space-between" alignItems="center" style={{ gap: '20px' }} mb="16px">
              <StyledHeading as="h2">{title}</StyledHeading>

              <StyledFlex alignItems="center">
                <Link href="/liquidity/all-pools" passHref>
                  <StyledButton id="pools" as="a">
                    {t('All Pools')}
                  </StyledButton>
                </Link>

                <Link href="/find" passHref>
                  <StyledButton id="import-pool-link" as="a">
                    {t('Import Pool')}
                  </StyledButton>
                </Link>
              </StyledFlex>
            </Flex>
          )} */}
          <Flex alignItems="center">
            {helper && <QuestionHelper color="white" text={helper} mr="4px" placement="top-start" />}
            <Text textAlign="left" fontSize="16px" fontWeight="200">
              {subtitle}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {!noConfig && (
        <Flex alignItems="center">
          <NotificationDot show={expertMode}>
            <GlobalSettings color="white" mr={0} />
          </NotificationDot>
          {/* <Transactions /> */}
        </Flex>
      )}
    </AppHeaderContainer>
  )
}

export default AppHeader
