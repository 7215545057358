import styled from 'styled-components'
import { Card } from 'packages/uikit'

export const BodyWrapper = styled(Card)<{ $maxWidth: number }>`
  border-radius: 10px;
  max-width: ${({ $maxWidth }) => $maxWidth}px;
  width: 100%;
  z-index: 1;
  background: var(--user-info-modal);
  border: 3px solid var(--primary);
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ maxWidth = 750, children }: { children: React.ReactNode; maxWidth?: number }) {
  return <BodyWrapper $maxWidth={maxWidth}>{children}</BodyWrapper>
}
