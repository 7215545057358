import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 30 18" {...props}>
      <g filter="url(#filter0_d_5935_4807)">
        <path
          d="M1 4.21429C1 2.43908 2.44646 1 4.23077 1C6.01507 1 7.46154 2.43908 7.46154 4.21429C7.46154 5.98949 6.01507 7.42857 4.23077 7.42857C2.44646 7.42857 1 5.98949 1 4.21429Z"
          fill="white"
        />
        <path
          d="M9.61539 4.21429C9.61539 2.43908 11.0618 1 12.8462 1H25.7692C27.5535 1 29 2.43908 29 4.21429C29 5.98949 27.5535 7.42857 25.7692 7.42857H12.8462C11.0618 7.42857 9.61539 5.98949 9.61539 4.21429Z"
          fill="white"
        />
        <path
          d="M1 12.7857C1 11.0105 2.44646 9.57143 4.23077 9.57143C6.01507 9.57143 7.46154 11.0105 7.46154 12.7857C7.46154 14.5609 6.01507 16 4.23077 16C2.44646 16 1 14.5609 1 12.7857Z"
          fill="white"
        />
        <path
          d="M9.61539 12.7857C9.61539 11.0105 11.0618 9.57143 12.8462 9.57143H25.7692C27.5535 9.57143 29 11.0105 29 12.7857C29 14.5609 27.5535 16 25.7692 16H12.8462C11.0618 16 9.61539 14.5609 9.61539 12.7857Z"
          fill="white"
        />
        <path
          d="M1 4.21429C1 2.43908 2.44646 1 4.23077 1C6.01507 1 7.46154 2.43908 7.46154 4.21429C7.46154 5.98949 6.01507 7.42857 4.23077 7.42857C2.44646 7.42857 1 5.98949 1 4.21429Z"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M9.61539 4.21429C9.61539 2.43908 11.0618 1 12.8462 1H25.7692C27.5535 1 29 2.43908 29 4.21429C29 5.98949 27.5535 7.42857 25.7692 7.42857H12.8462C11.0618 7.42857 9.61539 5.98949 9.61539 4.21429Z"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M1 12.7857C1 11.0105 2.44646 9.57143 4.23077 9.57143C6.01507 9.57143 7.46154 11.0105 7.46154 12.7857C7.46154 14.5609 6.01507 16 4.23077 16C2.44646 16 1 14.5609 1 12.7857Z"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M9.61539 12.7857C9.61539 11.0105 11.0618 9.57143 12.8462 9.57143H25.7692C27.5535 9.57143 29 11.0105 29 12.7857C29 14.5609 27.5535 16 25.7692 16H12.8462C11.0618 16 9.61539 14.5609 9.61539 12.7857Z"
          stroke="black"
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_5935_4807"
          x="0.25"
          y="0.75"
          width="29.5"
          height="16.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.65 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5935_4807" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5935_4807" result="shape" />
        </filter>
      </defs>
    </Svg>
  )
}

export default Icon
